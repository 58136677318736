import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'property',
    loadChildren: () => import('./pages/property/property.module').then( m => m.PropertyPageModule)
  },
  {
    path: 'plots',
    loadChildren: () => import('./pages/plots/plots.module').then( m => m.PlotsPageModule)
  },
  {
    path: 'add-plot',
    loadChildren: () => import('./pages/add-plot/add-plot.module').then( m => m.AddPlotPageModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('./pages/customers/customers.module').then( m => m.CustomersPageModule)
  },
  {
    path: 'add-customer',
    loadChildren: () => import('./pages/add-customer/add-customer.module').then( m => m.AddCustomerPageModule)
  },
  {
    path: 'see-customer',
    loadChildren: () => import('./pages/see-customer/see-customer.module').then( m => m.SeeCustomerPageModule)
  },
  {
    path: 'see-plot',
    loadChildren: () => import('./pages/see-plot/see-plot.module').then( m => m.SeePlotPageModule)
  },
  {
    path: 'see-booking',
    loadChildren: () => import('./pages/see-booking/see-booking.module').then( m => m.SeeBookingPageModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./pages/booking/booking.module').then( m => m.BookingPageModule)
  },
  {
    path: 'booking-plot',
    loadChildren: () => import('./pages/booking-plot/booking-plot.module').then( m => m.BookingPlotPageModule)
  },
  {
    path: 'instalment',
    loadChildren: () => import('./pages/instalment/instalment.module').then( m => m.InstalmentPageModule)
  },
  {
    path: 'add-book',
    loadChildren: () => import('./pages/add-book/add-book.module').then( m => m.AddBookPageModule)
  },
  {
    path: 'add-booking',
    loadChildren: () => import('./pages/add-booking/add-booking.module').then( m => m.AddBookingPageModule)
  },
  {
    path: 'accounts',
    loadChildren: () => import('./pages/accounts/accounts.module').then( m => m.AccountsPageModule)
  },
  {
    path: 'add-accu-data',
    loadChildren: () => import('./pages/add-accu-data/add-accu-data.module').then( m => m.AddAccuDataPageModule)
  },
  {
    path: 'see-accu-data',
    loadChildren: () => import('./pages/see-accu-data/see-accu-data.module').then( m => m.SeeAccuDataPageModule)
  },
  {
    path: 'modal',
    loadChildren: () => import('./pages/modal/modal.module').then( m => m.ModalPageModule)
  },
  {
    path: 'transfer-ownership',
    loadChildren: () => import('./pages/transfer-ownership/transfer-ownership.module').then( m => m.TransferOwnershipPageModule)
  },
  {
    path: 'graph-data',
    loadChildren: () => import('./pages/graph-data/graph-data.module').then( m => m.GraphDataPageModule)
  },
  {
    path: 'see-accu-data',
    loadChildren: () => import('./pages/see-accu-data/see-accu-data.module').then( m => m.SeeAccuDataPageModule)
  },
  {
    path: 'stardashboard',
    loadChildren: () => import('./pages/stardashboard/stardashboard.module').then( m => m.StardashboardPageModule)
  },
  {
    path: 'see-ledgers',
    loadChildren: () => import('./pages/see-ledgers/see-ledgers.module').then( m => m.SeeLedgersPageModule)
  },
  {
    path: 'all-instalments',
    loadChildren: () => import('./pages/all-instalments/all-instalments.module').then( m => m.AllInstalmentsPageModule)
  },  {
    path: 'print-installment',
    loadChildren: () => import('./print-installment/print-installment.module').then( m => m.PrintInstallmentPageModule)
  },
  {
    path: 'print-booking',
    loadChildren: () => import('./print-booking/print-booking.module').then( m => m.PrintBookingPageModule)
  },
  {
    path: 'instalmentprint',
    loadChildren: () => import('./pages/instalmentprint/instalmentprint.module').then( m => m.InstalmentprintPageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
