import { Component , ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSplitPane } from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  @ViewChild('ionSplitPane') ionSplitPane!: IonSplitPane;
 
  public menu: any =[{id:1 ,icon:'stats-chart', name:'Dashboard' , path: 'home'},
                     {id:2 ,icon:'map', name:'Property' , path: 'property'},
                     {id:3 ,icon:'log-out', name:'Logout' , path: 'login'},] 


                     clickMenuItem : any;
                     
  constructor(public route : Router) {}

  // clickMenuItem(item) {
  //   this.route.navigate([item]);

  // }
  disableIonSplitPange(){
    this.ionSplitPane.disabled = true;
      // if(item == 'login'){
    //   this.disableIonSplitPange();
    //   this.route.navigate([item]);
    // }
    // else{
    //   this.route.navigate([item]);
    // }
}
}
